import { useState } from "react"
import { MenuBurguer } from "./MenuBurguer";
import { Navbar } from "./Navbar"

import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from "react-router-dom";

import { language } from "../helpers/lang"

export const Header = () => {

    type T = keyof typeof language;
    const idioma = useParams();
    const lang:string = (idioma['*'])!.split('/')[0] || 'es'

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);
    }

    const handleLang = (e: any) => {
        navigate(`/${e.target.value}/`);
    }

    return (
        <div className="div_navbar">
            <nav className="navbar sticky-top">
                <div className="row row-nav">
                    <div className="col-md-3 col-md col-header">
                        <a 
                            href={`https://skycostanera.cl/${lang}/`}
                            target="_self"
                            rel="noreferrer"
                        >
                            <img
                                className="nav_img ms-3"
                                src={ require('../assets/img/logo_sky.png') }
                                alt='Logo Sky'
                            />
                        </a>
                    </div>
                    <div className="col-md-9 col-md col-header">
                        <div className="div_navbar2">
                            <ul className="navbar2">
                                <Navbar open={ open } />
                                <Form.Select
                                    className="select-menu"
                                    onChange={ handleLang }
                                >
                                    <option value="es">ES</option>
                                    <option value="en">EN</option>
                                    <option value="pt-br">PT</option>
                                </Form.Select>
                                <li className="menu-burguer">
                                    <MenuBurguer
                                        open={ open }
                                        handleClick={ handleClick }
                                    />
                                </li>
                                <li className="nav-item select-item">
                                    {language[lang as T].menuComprar}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
  )
}
