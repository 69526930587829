import Dropdown from 'react-bootstrap/Dropdown';
import { NavbarWrapper } from '../styles/navbarStyle';
import { useParams } from 'react-router-dom';

import { MdKeyboardArrowDown } from "react-icons/md";

import { language } from "../helpers/lang"

interface Props {
    open: boolean;
}

export const Navbar = ({ open }:Props) => {
    type T = keyof typeof language;
    const idioma = useParams();
    const lang:string = (idioma['*'])!.split('/')[0] || 'es'

    return (
        <NavbarWrapper 
            className='nav-wrapper'
            open = { open }
        >
            <li className="nav-item">
                <a 
                    href={`https://skycostanera.cl/${lang}/${language[lang as T].menuInicioLink}`}
                    target="_self"
                    rel="noreferrer"
                >
                    {language[lang as T].menuInicio}
                </a>
            </li>
            <li className="nav-item">
                <a 
                    href={`https://skycostanera.cl/${lang}/${language[lang as T].menuTarifHorLink}`}
                    target="_self"
                    rel="noreferrer"
                >
                    {language[lang as T].menuTarifHor}
                </a>
            </li>
            <li className="nav-item">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" >
                        {language[lang as T].menuActividades}
                        <MdKeyboardArrowDown style={{ marginLeft: '5px' }} size={18}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className='dropdown-menu'
                    >
                        <Dropdown.Item 
                            href={`https://skycostanera.cl/${lang}/${language[lang as T].menuActEventosLink}`}>
                                {language[lang as T].menuActEventos}
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href={`https://skycostanera.cl/${lang}/${language[lang as T].menuActVisitasLink}`}>
                                {language[lang as T].menuActVisitas}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </li>
            <li className="nav-item">
                <a 
                    href={`https://skycostanera.cl/${lang}/${language[lang as T].menuGaleriaLink}`}
                    target="_self"
                    rel="noreferrer"
                >
                    {language[lang as T].menuGaleria}
                </a>
            </li>
            <li className="nav-item nav-mob">
                {language[lang as T].menuComprar}
            </li>
        </NavbarWrapper>
    )
}
