import { FormEvent, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import 'moment/locale/es';
import Swal from 'sweetalert2';
import { ChangeEvent  } from 'react';
import { DataContext } from '../context/DataContext';
import { InitTitle } from '../components/InitTitle';
import { FaRegCalendar, FaRegClock, FaSpinner } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

import { language } from "../helpers/lang"

moment.locale('es');

/* interface LineItem {
    id: string;
    description: string;
    price: number;
    quantity: number;
} */
//type LineItems = LineItem[];

const initialValues = {
    paytype: 0 
}

export const Paypage = () => {

    type T = keyof typeof language;

    const idioma = useParams();
    const lang:string = idioma.lang || 'es';

    const { dataState, setPayIntention, setTermsAndConditions } = useContext(DataContext);

    const { slotSelected, visitor, buyer, hour, visitantes } = dataState;
    
    const [ descVisitor, setDescVisitor ] = useState('');

    const [ formValues, setFormValues ] = useState(initialValues);
    const [ check, setCheck ] = useState(false);

    const [ sendPay, setSendPay ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(Object.keys(slotSelected).length === 0){
            navigate(`/${lang}/`);
            return;
        } 

        let descripcion = "";
        if(visitantes && visitantes.length > 0){
            visitantes.forEach((ticket: any) => {
                descripcion = descripcion + ticket.count + " " + ticket.name + " - ";
            });
        }
        descripcion = descripcion.slice(0, -2)
        setDescVisitor(descripcion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const total = () => {
        let total = 0;
        if(visitantes && visitantes.length > 0){
            visitantes.forEach((ticket: any) => {
                 total = total + (ticket.count * ticket.valor)
            });
        }
        return total;
    } 
    
    const totalCount = () => {
        let count = 0;
        if(visitantes && visitantes.length > 0){
            visitantes.forEach((ticket: any) => {
                 count = count + ticket.count
            });
        }
        return count;
    }

    const handleInputChange = ({ target }:ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [target.name]: (target.checked) ? 1 : 0
        });
        handlePayMethod();

    }

    const handlePayMethod = () => {
        setFormValues({
            ...formValues,
            paytype: (formValues.paytype === 1)? 0 : 1
        });
        setCheck((formValues.paytype === 1)? false : true);
    }

    const handleBack = () => {
        navigate(`/${lang}/form`);
    }

    const validarRut = (rut: string): boolean => {
        if (!/^[0-9]+-[0-9kK]{1}$/.test(rut) || rut.length > 10) {
          return false;
        }
        const sinGuion = rut.replace("-", "");
        const rutSinDigito = sinGuion.slice(0, -1);
        const digitoVerificador = sinGuion.slice(-1).toUpperCase();
        let suma = 0;
        let multiplo = 2;
        for (let i = rutSinDigito.length - 1; i >= 0; i--) {
          suma += parseInt(rutSinDigito.charAt(i)) * multiplo;
          multiplo = multiplo === 7 ? 2 : multiplo + 1;
        }
        const dvCalculado = 11 - (suma % 11);
        const dvEsperado = dvCalculado === 11 ? "0" : dvCalculado === 10 ? "K" : dvCalculado.toString();
        return dvEsperado === digitoVerificador;
      }

    const handleConfirm = async (e: FormEvent<HTMLFormElement> ) => {
        e.preventDefault();
        setSendPay(true);
        if (formValues.paytype === 0) {
            Swal.fire('Error', 'Debe elegir medio de pago', 'error')
        } else { 


            ReactPixel.track('AddPaymentInfo');

            localStorage.setItem('timePage', new Date().toString());

            const validate = validarRut(buyer.rut!);
            if (!validate) {
                buyer.rut = '66666666-6'
            }

            const objPay = {
                intent: 'SALE',
                payer: {
                    document_number: buyer.rut ? buyer.rut : '66666666-6',
                    email: buyer.email,
                    full_name: (buyer.name).trim() + ' ' + (buyer.lastname).trim(),
                    birthday: buyer.birthday ? buyer.birthday : undefined,
                },
                transaction: {
                    merchant_transaction_id: uuidv4(),
                }
            }

            setTermsAndConditions(buyer); 
            //DESCOMENTAR
            const payInt = await setPayIntention(objPay, slotSelected.start, visitor, lang, visitantes );
            if (!payInt) {
                setSendPay(false);
            }
            //setSendPay(false);

            //todo: clean trx
        }

    }

    return (
        ((slotSelected) || Object.keys(slotSelected).length > 0)
        ?
        <>
            <InitTitle />
            <div className="box_main container-fluid mt-3 mb-2">
                <div className="box-container mb-5">
                    <form
                        className='d-block'
                        onSubmit={ handleConfirm }
                    >
                        <h4>{language[lang as T].resumeCompra}</h4>
                        <div className="row">
                            <div className="col-md">
                                <div className="input_form input_date">
                                    <FaRegCalendar className='me-2'/>
                                    <input
                                        className='input-inside'
                                        value={ moment(slotSelected.start).format('dddd D MMMM YYYY')! }
                                        readOnly
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="col-md">
                                <div className="input_form input_date">
                                    <FaRegClock className='me-2'/>
                                    <input
                                        className='input-inside'
                                        value={ hour }
                                        readOnly
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className="input_form d-flex justify-content-between align-items-center">
                                    <input
                                        className='border-0 input-wobor'
                                        placeholder={language[lang as T].payCodigo}
                                    />
                                    <span
                                        className='text-end link-span span-desc'
                                        onClick={() => {
                                            alert(`${language[lang as T].payDescribe}`)}
                                        }
                                    >
                                        {language[lang as T].payAplicar}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className='d-block p-2'
                            >
                                <div
                                    className="row wborder back-primary mb-3 ms-1 select-tipo-ent"
                                >
                                    <div className="col-md">
                                        <h5
                                            className='mb-0 title-total'
                                        >
                                            Total
                                        </h5>
                                    </div>

                                    <div className="col-md sd_respon_2">
                                        <strong>{ descVisitor }</strong>
                                    </div>
                                    <div className="col-md text-end">
                                        { (total()) ?  '$' + (total()!).toLocaleString("de-DE",{style: "currency", currency: "CLP"}) : 0 }
                                    </div>
                                    <div
                                        className="col-md text-end"
                                    >
                                        <button
                                            className='btn_counter btn-inv'
                                            disabled={true}
                                        >
                                            -
                                        </button>
                                        <input
                                            className='counter text-center back-primary'
                                            value={ totalCount() }
                                            readOnly
                                        />

                                        <button
                                            className='btn_counter btn-inv'
                                            disabled={true}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        {/* Warning Devolucione */}
                        <div className="row mb-3">
                            <h5>* {language[lang as T].payReturn}</h5>
                        </div>

                        <div className="row mb-1">
                            <h4 className='mt-2'>{language[lang as T].payMedio}</h4>
                        </div>

                        <div>
                            <div className="row">
                                <div className="form-check d-flex">
                                    <input
                                        className='input-radio'
                                        type='checkbox'
                                        name='paytype'
                                        id='type1'
                                        value={ formValues.paytype! }
                                        checked={ check }
                                        onChange={ handleInputChange }
                                    />
                                    <div
                                        className={`input_form 
                                                   input-paymethod 
                                                   d-flex 
                                                   justify-content-between 
                                                   align-items-center
                                                   ${ formValues.paytype === 1 ? 'dark-pay' : '' } }`}
                                        onClick={ handlePayMethod }
                                    >
                                        <span>{language[lang as T].payMethod1}</span>
                                        <img
                                            className='img_trj'
                                            src={ require('../assets/img/BotonWebPay-img.jpg') }
                                            alt= 'WebPay'
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md text-end">
                                <button
                                    onClick={ handleBack }
                                    className='btn-form me-2'
                                    type='button'
                                    >
                                    {language[lang as T].formBack}
                                </button>
                                {
                                    ( sendPay )
                                    ?
                                        <button
                                            disabled
                                            className='btn-form btn-form-disabled'
                                            type='submit'
                                        >
                                            {language[lang as T].payPagar} <FaSpinner className='fa-spin' />
                                        </button>
                                    :
                                        <button
                                            disabled={ !check ? true : false }
                                            className={`btn-form ${ check ? '' : 'btn-form-disabled' }`}
                                            type='submit'
                                        >
                                            {language[lang as T].payPagar}
                                        </button>
                                }
                                {/* <button
                                    className='btn-form'
                                    type='submit'
                                >
                                    {language[lang as T].payPagar}
                                </button> */}
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
        : <h2>Redirigiendo</h2>
    )
}
