import { useParams } from "react-router-dom";
import { language } from "../helpers/lang"

export const InitTitle = () => {

  type T = keyof typeof language;

  const idioma = useParams();
  const lang:string = idioma.lang || 'es'

  return (
    <div className="title container-fluid">
        <h3>
          {/* COMIENZA TU VISITA AL MIRADOR SKY COSTANERA */}
          {language[lang as T].inittitle}
        </h3>
        <p className="mt-3">
          {language[lang as T].inititparr}
        </p>
    </div>
  )
}
