import { BrowserRouter, Route, Routes } from "react-router-dom"
import { MainPage } from '../pages/MainPage';

export const AppRoute = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/*" element={ <MainPage /> } />
        </Routes>
    </BrowserRouter>
  )
}
