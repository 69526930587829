import { useEffect, useState } from 'react';

type Theme = 'dark' | 'light';

export const HelpPage = () => {

    const [theme, setTheme] = useState<Theme>('light');
    const [fontSize, setFontSize] = useState(16);

    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    },[theme]);

    const handleUpSize = () => {
        setFontSize(prev => Math.min(prev + 1, 30));
    }

    const handleDownSize = () => {
        setFontSize(prev => Math.max(prev - 1, 16));
    }

    return (
        <div className='title container-fluid wrapper-terms'>
            <h4>¿QUÉ ES ACCESIBILIDAD?</h4>
            <div className="container-btn">
                <div className="btn-group-terms">
                    <button
                        className='btn-terms'
                        onClick={ handleUpSize }
                    >
                        A+
                    </button>
                    <button
                        className='btn-terms'
                        onClick={ handleDownSize }
                    >
                        A-
                    </button>
                    <button
                        className='btn-terms btn-contrast'
                        onClick={ e => {
                            e.preventDefault();
                            setTheme(theme === 'light' ? 'dark' : 'light');
                        }}
                    >
                        &nbsp;
                    </button>
                </div>
            </div>

            <div
                className='container-fluid'
            >
                <p className='mt-3' style={{fontSize: `${fontSize}px`}}>
                    La accesibilidad busca que todas las personas que son usuarias de un sitio web puedan navegar e interactuar, sin importar 
                    si es que tienen o no algún grado de discapacidad.
                </p>
                <p className='mt-2' style={{fontSize: `${fontSize}px`}}>
                    En Sky Costanera nos hemos preocupado de que nuestros términos y condiciones cumplan con los requisitos mínimos recomendados 
                    por el Servicio Nacional de la Discapacidad (Senadis) basados en los estándares entregados por el consorcio de la World Wide 
                    Web en sus Pautas de Accesibilidad para el Contenido Web (WCAG) 2.0.
                </p>
                <p className='mt-4 fw-bold' style={{fontSize: `${fontSize}px`}}>
                    Tamaño de letras <button className='btn-terms ms-3'>A+</button> <button className='btn-terms ms-3' disabled>A-</button>
                </p>
                <p className='mt-2' style={{fontSize: `${fontSize}px`}}>
                    La fuente de esta página tiene dimensiones relativas, así las personas podrán controlar los tamaños con los controles 
                    habilitados.
                </p>
                <p className='mt-4 fw-bold' style={{fontSize: `${fontSize}px`}}>
                    Contraste <button className='btn-terms btn-contrast ms-3'>&nbsp;</button>
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    Habilitamos una opción con mayor contraste para mejorar la legibilidad de los textos en las personas que puedan necesitarlo.
                </p>
                <p className='mt-4 fw-bold' style={{fontSize: `${fontSize}px`}}>
                    Lectores de Pantalla
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    Nos preocupamos de habilitar el uso de los lectores de pantalla e integramos elementos de navegación del teclado para 
                    que las personas puedan utilizarlo mediante un lector de pantalla. Para poder utilizarlo, debes bajar una extensión o 
                    instalar un software de Screen Reader. Algunos ejemplos gratuitos que puedes utilizar son:
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    <ul>
                        <li>NVDA (para Windows)</li>
                        <li>VoiceOver (integrado en dispositivos Apple)</li>
                        <li>Screen reader (extensión de Google Chrome)</li>
                    </ul>
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    En celulares, solo debes ir a la configuración y activar la opción de VoiceOver en dispositivos iOS o TalkBack en 
                    Android.
                </p>

            </div>
        </div>
    )
}
