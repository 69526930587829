import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Theme = 'dark' | 'light';

export const TermsPage = () => {

    const [theme, setTheme] = useState<Theme>('light');
    const [fontSize, setFontSize] = useState(16);

    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    },[theme]);

    const navigate = useNavigate();

    const handleUpSize = () => {
        setFontSize(prev => Math.min(prev + 1, 30));
    }

    const handleDownSize = () => {
        setFontSize(prev => Math.max(prev - 1, 16));
    }

    const handleHelp = () => {
        navigate('/help');
    }

    return (
        <div className='title container-fluid wrapper-terms'>
            <div className="container-btn">
                <div className="btn-group-terms">
                    <button
                        className='btn-terms'
                        onClick={ handleUpSize }
                    >
                        A+
                    </button>
                    <button
                        className='btn-terms'
                        onClick={ handleDownSize }
                    >
                        A-
                    </button>
                    <button
                        className='btn-terms btn-contrast'
                        onClick={ e => {
                            e.preventDefault();
                            setTheme(theme === 'light' ? 'dark' : 'light');
                        }}
                    >
                        &nbsp;
                    </button>
                    <button className='btn-terms'
                        onClick={ handleHelp }
                    >
                        ?
                    </button>
                </div>
            </div>

            <div
                className='container-fluid'
            >
                <h4>TÉRMINOS Y CONDICIONES AL REVERSO DEL BOLETO DE ACCESSO</h4>
                <p className='mt-3' style={{fontSize: `${fontSize}px`}}>
                    Este boleto es válido para el lugar, la fecha y hora indicada en el anverso y permitirá a su portador el acceso a Sky 
                    Costanera, sujeto a las siguientes condiciones:
                </p>
                <p className='mt-2' style={{fontSize: `${fontSize}px`}}>
                    1) Previo al acceso, el portador de este boleto será objeto de una revisión efectuado por razones de seguridad por 
                    parte del personal de Sky Costanera.
                </p>
                <p className='mt-2' style={{fontSize: `${fontSize}px`}}>
                    2) El portador de este boleto no podrá acceder al Sky Costanera o será desalojado de éste, sin derecho a reembolso de 
                    dinero, en caso que no permita dicha revisión que porte, consuma o se encuentre bajo efectos de bebidas alcohólicas, 
                    drogas y estupefacientes ilegales en general; armas de cualquier tipo u otro artículo riesgoso para la salud e integridad 
                    de las personas tales como encendedores, botellas de vidrio y sustancias inflamables, o que tenga una conducta riesgosa, 
                    temeraria, contraria a la moral , las buenas costumbres o al orden público en las instalaciones.
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    3) El portador de este boleto deberá conservar la integridad y legibilidad del mismo, evitando su deterioro y exposición 
                    al sol o a cualquier fuente de calor para no dañar su impresión térmica, este boleto podrá ser solicitado por personal de 
                    Sky Costanera durante la duración de su visita.
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    4) Los menores de edad no podrán hacer ingreso a Sky Costanera sino en compañía de un adulto, este último, quién será 
                    responsable de la conducta de éstos al interior del establecimiento.
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    5) No se permite el ingreso de animales.
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    6) La vigencia de este boleto podrá ser postergada por la administración de Sky Costanera cuando fuere necesario debido 
                    a la ocurrencia de casos fortuitos, fuerza mayor o razones de seguridad, en cuyo evento, se anunciará al público de esta 
                    circunstancia y las nuevas fechas dentro de las cuales podrá el portador acceder a Sky Costanera.
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    7) No podrán tomarse fotografías en Sky Costanera para efectos comerciales o publicitarios, sino solo para uso privado 
                    y personal.
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    8) La administración de Sky Costanera no recomienda el ingreso de las instalaciones de mujeres embarazadas o personas 
                    con enfermedades cardíacas o que afecten la presión sanguínea u otros padecimientos que convoquen síntomas o reaccionen a la altura o presión atmosférica, con lo cual, aquella no será responsable del riesgo que asuman o daños que sufran éstas en tales instalaciones.
                </p>
                <p className="mt-2" style={{fontSize: `${fontSize}px`}}>
                    9) Una vez realizada la compra de este boleto no se admitirán devoluciones sino sólo por causas legales. tampoco podrá 
                    ser objeto de reventa o regalo en promociones u ofertas sin la expresa autorización de la administración de Sky Costanera 
                    otorgada por escrito. Sky Costanera no es responsable por boletos no adquiridos a través del sistema de ventas de entrada. 
                    Para mayor detalle de las instrucciones, exigencias y normas de higiene y/o seguridad que el portador de este boleto debe 
                    cumplir  al interior de Sky Costanera y otras condiciones de venta, Ud. puede visitar el sitio www.skycostanera.cl
                </p>

            </div>
        </div>
    )
}
