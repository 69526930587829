/* eslint-disable react-hooks/exhaustive-deps */
import { FormEvent, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { DataContext } from '../context/DataContext';
import { InitTitle } from '../components/InitTitle';
import { FaRegCalendar, FaRegClock } from 'react-icons/fa';
import ReactPixel from 'react-facebook-pixel';

import { language } from "../helpers/lang";


export const ResumeBuy = () => {

    type T = keyof typeof language;

    const idioma = useParams();
    const lang:string = idioma.lang || 'es'

    const { dataState } = useContext(DataContext);
    const { slotSelected, /*visitor,*/ hour, visitantes } = dataState;

    const navigate = useNavigate();

    useEffect(() => {
        if(Object.keys(slotSelected).length === 0){
            navigate(`/${lang}/`);
            return;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, slotSelected])  
    
    useEffect(() => { 
      ReactPixel.track("InitiateCheckout", {
        content_category: "tickets",
        content_ids: visitantes.map((ticket: any)=> ticket.num_ticket),
        contents: visitantes.map((ticket: any)=> {return {id: ticket.num_ticket, quantity: ticket.count}}),
        currency: "CLP",
        num_items: totalCount(),
        value: total(),
      });
    }, []);

    const description = () => {
        let descripcion = "";
        if(visitantes && visitantes.length > 0){
            visitantes.forEach((ticket: any) => {
                descripcion = descripcion + ticket.count + " " + ticket.name + " - ";
            });
        }
        descripcion = descripcion.slice(0, -2)
        return descripcion;
    }

    const total = () => {
        let total = 0;
        if(visitantes && visitantes.length > 0){
            visitantes.forEach((ticket: any) => {
                 total = total + (ticket.count * ticket.valor)
            });
        }
        return total;
    } 

    const totalCount = () => {
        let count = 0;
        if(visitantes && visitantes.length > 0){
            visitantes.forEach((ticket: any) => {
                 count = count + ticket.count
            });
        }
        return count;
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      ReactPixel.track("AddPaymentInfo", {
        content_category: "tickets",
        content_ids: visitantes.map((ticket: any)=> ticket.num_ticket),
        contents: visitantes.map((ticket: any)=> {return {id: ticket.num_ticket, quantity: ticket.count}}),
        currency: "CLP",
        value: total(),
      });
      navigate(`/${lang}/form`);
    };


  return (
    <>
        <InitTitle />
        <div className="box_main container-fluid mt-3">
            <div className='box-container'>
            <form
                onSubmit={ handleSubmit }
            >
                <h4>{language[lang as T].resumeCompra}</h4>
                <div className="row">
                    <div className="col-md">
                        <div className="input_form input_date">
                            <FaRegCalendar className='me-2'/>
                            <input
                                className='input-inside'
                                value={ moment(slotSelected.start).format('dddd D MMMM YYYY')! }
                                readOnly
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="input_form input_date">
                            <FaRegClock className='me-2'/>
                            <input
                                className='input-inside'
                                value={ hour }
                                readOnly
                                disabled
                            />
                        </div>

                    </div>
                </div>

                {visitantes && visitantes.length > 0 ? visitantes.map((ticket: any) => {
                    return(
                        <div key={ticket.id} className='d-block mt-2'>
                            <div className="row wborder mb-2 select-tipo-ent">
                                <div className="col-md">
                                    <span>{ticket.name}</span>
                                </div>
                                <div className="col-md sd_respon_2">
                                    {ticket.description}
                                </div>
                                <div className="col-md sd_respon"></div>
                                <div className="col-md text-end">
                                    { '$' + (ticket.valor).toLocaleString("de-DE",{style: "currency", currency: "CLP"}) }
                                </div>
                                <div className="col-md text-end">
                                    <button className='btn_counter' disabled onClick={ e => e.preventDefault() }>-</button>
                                    <input className='counter text-center' value={ ticket.count } readOnly />
                                    <button className='btn_counter' disabled onClick={ e => e.preventDefault() } >+</button>
                                </div>
                            </div>
                        </div>)
                }) : <></>}
                <div className='d-block'>
                    <div className="row wborder back-primary mb-2 select-tipo-ent">
                        <div className="col-md">
                            <strong className='text-total'>Total</strong>
                        </div>
                        <div className="col-md sd_respon_2"></div>
                        <div className="col-md sd_respon">
                            <strong>{ description() }</strong>
                        </div>
                        <div className="col-md text-end">
                            { '$' + (total()!).toLocaleString("de-DE",{style: "currency", currency: "CLP"}) }
                        </div>
                        <div className="col-md text-end" >
                            <button disabled className='btn_counter btn-inv' onClick={ e => e.preventDefault()}> - </button>
                            <input className='counter text-center back-primary' value={ totalCount() } readOnly />
                            <button disabled className='btn_counter btn-inv' onClick={ e => e.preventDefault() }> + </button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md text-end">
                        <button className='btn-form' type='submit' >
                            {language[lang as T].resumeConfirmar}
                        </button>
                    </div>
                </div>
            </form>
            </div>
        </div>
    </>
  )
}
