import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { MainRoutes } from "../routes/MainRoutes"

export const MainPage = () => {
  return (
    <div
      className="middle_screen"
    >
        <Header />
        <MainRoutes />
        <Footer />
    </div>
  )
}
