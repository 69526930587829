import { DataProvider } from './context/DataProvider';
import { AppRoute } from './routes/AppRoute';

function App() {
  return (
    <DataProvider>
      <div className="App App-header">
        <AppRoute />
      </div>
    </DataProvider>
  );
}

export default App;
