import { buyer, FlowData, PayProps, ReserveProps, TrxResponse, TrxDetailResponse, invoice } from '../interfaces/interfaces';

interface visitor {
    adult: number;
    child: number;
    fast: number;
}


type Actions =
| { type: 'setSlots', payload: [{}] } //Slots
| { type: 'setReserve', payload: ReserveProps }
| { type: 'setRegiones', payload: [{}]} //Regiones
| { type: 'cleanReserve' }
| { type: 'setDayAndHour', payload: { day: string, hour: string } }
| { type: 'setVisitor', payload: visitor }
| { type: 'setBuyer', payload: buyer }
| { type: 'cleanBuyer' }
| { type: 'setInvoice', payload: invoice }
| { type: 'setPayIntention', payload: PayProps }
| { type: 'setPayData', payload: { id: string } }
| { type: 'setTrxResponse', payload: TrxResponse }
| { type: 'setTrxDetail', payload: TrxDetailResponse }
| { type: 'setVisitantes', payload: any }

export const dataReducer = ( state: FlowData, action: Actions ): FlowData => {


    switch ( action.type ) {
        case 'setSlots':
            return {
                ...state,
                slots: action.payload
            }

        case 'setReserve':
            return {
                ...state,
                slotSelected: action.payload
            }

        case 'setRegiones':
            return {
                ...state,
                regiones: action.payload
            }

        case 'cleanReserve':
            return {
                ...state,
                slotSelected: {}
            }

        case 'setDayAndHour': {
            return {
                ...state,
                day: action.payload.day,
                hour: action.payload.hour
            }
        }

        case 'setVisitor': {
            return {
                ...state,
                visitor: action.payload
            }
        }

        case 'setBuyer': {
            return {
                ...state,
                buyer: action.payload
            }
        }

        case 'cleanBuyer': {
            return {
                ...state,
                buyer: {
                    name: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    residencia: "",
                    nation: "",
                    rut: "",
                }
            }
        }

        case 'setInvoice': {
            return {
                ...state,
                invoice: action.payload
            }
        }

        case 'setPayIntention': {
            return {
                ...state,
                payIntention: action.payload
            }
        }

        case 'setPayData': {
            return {
                ...state,
                id: action.payload.id
            }
        }

        case 'setTrxResponse': {
            return {
                ...state,
                trxResponse: action.payload
            }
        }

        case 'setTrxDetail': {
            return {
                ...state,
                trxDetail: action.payload
            }
        }
        case 'setVisitantes': {
            return {
                ...state,
                visitantes: action.payload
            }
        }

        default:
            return state;
    }
}