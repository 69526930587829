import { ImFacebook2, ImYoutube } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import TripAdvisor from "../assets/img/tripadvisor-logo-2.png";

import { language } from "../helpers/lang"
import { useParams } from "react-router-dom";

/*
footerContacto: `CONTACT US`,
        footerConFormulario: `CONTACT FORM`,
        footerConCorreo: `SKYCOSTANERA2@CENCOSUD.CL`,
        footerConTelefono: `+562 2916 9269 ANEXO 511269`,
        footerConDireccion: `GROUND FLOOR MALL COSTANERA CENTER`,
        footerEnlace: `LINKS OF INTEREST`,
        footerEnlaceMall: `MALL COSTANERA CENTER`,
        footerEnlaceTerm: `TERMS AND CONDITIONS`,
        footerEnlaceFaq: `FREQUENTLY ASKED QUESTIONS - FAQ`,
        footerRss: `FOLLOW US ON RRSS`,
        footerRssTrip: `Read 15,097 Reviews of Sky Costanera`,*/

export const Footer = () => {

    type T = keyof typeof language;
    const idioma = useParams();
    const lang:string = (idioma['*'])!.split('/')[0] || 'es'
    
    return (
        <div className="contain_footer">
            <div className="footer">
                <div
                    className="row box_footer"
                >
                    <div className="col-md mt-4 mb-3">
                    <h4 className='mb-3'>{language[lang as T].footerContacto}</h4>
                        <p><a href={`https://skycostanera.cl/${lang}/${language[lang as T].footerConFormLink}`} target="_self">{language[lang as T].footerConFormulario}</a></p>
                        <p><a href="mailto:skycostanera2@cencosud.cl" target="_blank" rel="noreferrer">{language[lang as T].footerConCorreo}</a></p>
                        <p>{language[lang as T].footerConTelefono}</p>
                        <p>{language[lang as T].footerConDireccion}</p>
                    </div>

                    <div className='col-md mt-4 mb-3'>
                        <h4 className='mb-3'>{language[lang as T].footerEnlace}</h4>
                        <p><a href="https://mall.costaneracenter.cl" target="_blank" rel="noreferrer">{language[lang as T].footerEnlaceMall}</a></p>
                        <p><a href="https://cencosud.com" target="_blank" rel="noreferrer">CENCOSUD</a></p>
                        <p><a href={`https://skycostanera.cl/${lang}/${language[lang as T].footerEnlaceTermLink}`} target="_self" rel="noreferrer">{language[lang as T].footerEnlaceTerm}</a></p>
                        <p><a href={`https://skycostanera.cl/${lang}/${language[lang as T].footerEnlaceFaqLink}`} target="_self" rel="noreferrer">{language[lang as T].footerEnlaceFaq}</a></p>
                    </div>

                    <div className='col-md mt-4 mb-3'>
                        <h4 className='mb-3'>{language[lang as T].footerRss}</h4>
                        <div className='d-flex box_rrss'>
                            <a 
                                href="https://www.facebook.com/SkyCostanera" 
                                target="_blank" 
                                rel="noreferrer"
                                className="me-4"
                            >
                                <ImFacebook2 
                                    size={'32px'}
                                    color={'white'}
                                />
                            </a>
                            <a 
                                href="https://www.instagram.com/accounts/login/?next=%2Fskycostanera%2F&source=omni_redirect" 
                                target="_blank" 
                                rel="noreferrer"
                                className="me-4"
                            >
                                <BsInstagram 
                                    size={'32px'}
                                    color={'white'}
                                />
                            </a>
                            <a 
                                href="https://www.youtube.com/watch?v=jMnYCYW4d6Y&t=11s" 
                                target="_blank" 
                                rel="noreferrer"
                                className="me-4"
                            >
                                <ImYoutube 
                                    size={'32px'}
                                    color={'white'} 
                                />
                            </a>
                        </div>
                        <div className='mt-3 d-flex box_trip'>
                                <div className="col-xl-5 border border-light text-center">
                                    <a 
                                        href="https://www.tripadvisor.cl/Attraction_Review-g294305-d8594663-Reviews-Sky_Costanera-Santiago_Santiago_Metropolitan_Region.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={TripAdvisor} alt="logo_trip" width='57%' style={{marginTop: '-10px'}}/>
                                    </a>
                                </div>
                            <div className="col-xl-7 border border-light text-center">
                                <h6 className="text-center">{language[lang as T].footerRssTrip}</h6>
                            </div>
                        </div>

                    </div>

                    <div className='col-md img-desktop mt-4 mb-3 text-center'>
                        <img
                            className='nav_img_footer'
                            src={ require('../assets/img/logo_sky_white.png') }
                            alt= 'Logo'
                        />
                    </div>

                </div>

                <div className="row justify-content-center img-mob">
                    <img
                        className='nav_img_footer'
                        src={ require('../assets/img/logo_sky_white.png') }
                        alt= 'Logo'
                    />
                </div>

            </div>
        </div>
    )
}
