import moment from "moment";
//import { holidays } from "./holidays";
//import { te } from "date-fns/locale";

/* interface Holiday {
  day: number;
  month: number;
  startTime: string;
  open: boolean;
} */

export const reserveSlotTemp = (date: string, hour: number) => {

  /* const timeZone:number = (
    moment(date).format("YYYY-MM-DD") > "2023-09-02" //TODO: cambiar fecha o automatizar
      ? parseInt(process.env.REACT_APP_TIME_SUMMER!) 
      : parseInt(process.env.REACT_APP_TIME_WINTER!)
  ) * (-1); */

  const timeZone:number = process.env.REACT_APP_TIME_WINTER ? parseInt(process.env.REACT_APP_TIME_WINTER)*(-1) : -240;

  //console.log(date, hour);
  
  let tempHora = moment(`${date}T00:00:00`).add(hour, 'hours');
  let tempHoraGmt = tempHora.clone().utcOffset(timeZone,true);
  let tempHoraGmt2 = tempHora.clone().utcOffset(timeZone,true);
  
  //let dia = moment(date).utcOffset(-240).set('hour', hour).set('minute', 0).toISOString();
  let dia = tempHoraGmt.toISOString();
  //let diaFin = moment(date).utcOffset(-240).set('hour', hour).set('minute', 59).set('second', 59).toISOString();
  let diaFin = tempHoraGmt2.add(59, 'minutes').toISOString();
  
  //console.log(new Date(date));
  
  //let dayWeek = new Date(date).getDay() || 0;
  /* let dayWeek = moment(date).weekday() || 0;
  const searchHoliday = holidays.find( (holiday: Holiday) => 
      holiday.day === parseInt(date.split('-')[2]) && 
      holiday.month === parseInt(date.split('-')[1])
  ); */

  /* if (searchHoliday) {
      dayWeek = 6;
  } */
  
  //console.log(dayWeek);
  //console.log(moment(date).weekday());

  let priceAdulto = 0
  let priceNino = 0
  let priceSunset = 0

  if (date >= "2024-01-22") {
    priceAdulto = 18000;
    priceNino = 6000;
    priceSunset = 20000;
  } else {
    /* if (dayWeek >= 0 && dayWeek <= 3) {
      priceAdulto = 12000;
      priceNino = 5000;
    } else {
      priceAdulto = 16000;
      priceNino = 0;
    } */
    priceAdulto = 16000;
    priceNino = 6000;
    priceSunset = 20000;
  }


  const reserveTmp = {
      enabled: true,
      start: dia,
      end: diaFin,
      ticket_types: [
        {
          type: 'ADULT',
          name: 'Adulto',
          price: priceAdulto,
        },
        {
          type: 'CHILD',
          name: 'Niño',
          price: priceNino,
        },
        {
          type: 'SUNSET',
          name: 'Sunset',
          price: priceSunset,
        }
      ]
    };

    return reserveTmp
}
