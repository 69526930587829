import { ChangeEvent, FormEvent, useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { FormProps } from '../interfaces/interfaces';
import { DataContext } from "../context/DataContext";
import { InitTitle } from "../components/InitTitle";
import { es } from 'date-fns/locale';
import DatePicker from 'react-datepicker';

import { language } from "../helpers/lang";
import Swal from 'sweetalert2';
//import moment from 'moment';



const initValues: FormProps = {
    name: "",
    lastname: "",
    email: "",
    birthday: null,
    phone: "",
    residencia: "0",
    nation: "",
    region: 0,
    rut: "66.666.666-6",
    dni: "",
    promo: false,
    terms: false,
}

export const FormPage = () => {

    type T = keyof typeof language;

    const idioma = useParams();
    const lang: string = idioma.lang || 'es'

    const { dataState, setBuyer, getRegiones } = useContext(DataContext);
    const { regiones, buyer, slotSelected /*visitor*/ } = dataState;

    const [selected, setSelected] = useState('CL');
    const [date, setDate] = useState<Date | null>(null);

    const [formValues, setFormValues] = useState(buyer || initValues);

    const navigate = useNavigate();

    useEffect(() => {
        getRegiones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Object.keys(slotSelected).length === 0) {
            navigate(`/${lang}/`);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, slotSelected])

    const reg = useRef<HTMLSelectElement>(null);

    const validarRut = (rut: string): boolean => {
        if (!/^[0-9]+-[0-9kK]{1}$/.test(rut) || rut.length > 10) {
            return false;
        }
        const sinGuion = rut.replace("-", "");
        const rutSinDigito = sinGuion.slice(0, -1);
        const digitoVerificador = sinGuion.slice(-1).toUpperCase();
        let suma = 0;
        let multiplo = 2;
        for (let i = rutSinDigito.length - 1; i >= 0; i--) {
            suma += parseInt(rutSinDigito.charAt(i)) * multiplo;
            multiplo = multiplo === 7 ? 2 : multiplo + 1;
        }
        const dvCalculado = 11 - (suma % 11);
        const dvEsperado = dvCalculado === 11 ? "0" : dvCalculado === 10 ? "K" : dvCalculado.toString();
        return dvEsperado === digitoVerificador;
    }

    const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {

        let value = target.type === 'checkbox' ? target.checked : target.value;

        if (target.name === 'rut') {
            value = target.value.replaceAll('.', '');

            setFormValues({
                ...formValues,
                rut: value.toString(),
                dni: value.toString()
            });
        } else {
            setFormValues({
                ...formValues,
                [target.name]: value
            });
        }

    }

    const handleRut = (e: any) => {
        if ((e.target.value).includes("-")) {
            const validate = validarRut(e.target.value);
            if (!validate) {
                Swal.fire('Error', 'Error en Rut', 'error');
                e.target.value = ""
            }
        }
    }

    const handleSelectChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    const handleBack = () => {
        navigate(`/${lang}/`);
    }


    const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        //localStorage.setItem('residencia', formValues.residencia);
        //localStorage.setItem('region', formValues.region ? formValues.region.toString() : '7');
        //localStorage.setItem('promo', formValues.promo ? '1' : '0' );
        //localStorage.setItem('nation', formValues.nation ? formValues.nation : 'CL');
        //localStorage.setItem('phone', formValues.phone? formValues.phone : '0');
        //localStorage.setItem('terms', formValues.terms ? '1' : '0' );
        setBuyer(formValues);
        navigate(`/${lang}/pay`);
    }


    return (
        <>
            <InitTitle />
            <div className="box_main container-fluid mt-3 mb-2">
                <div className='box-container'>
                    <form
                        //className='d-block'
                        onSubmit={ handleSubmitForm }
                    >
                    <h4>
                        {language[lang as T].formTitle}
                    </h4>
                        <div className="row row_form">
                            <div className="col-md">
                                <input
                                    className='input_form'
                                    placeholder={language[lang as T].formName}
                                    type='text'
                                    name='name'
                                    required={true}
                                    onChange={ handleInputChange }
                                    value={ formValues.name! }
                                />
                            </div>
                            <div className="col-md">
                                <input
                                    className='input_form'
                                    placeholder={language[lang as T].formLastName}
                                    type='text'
                                    name='lastname'
                                    required={true}
                                    onChange={ handleInputChange }
                                    value={ formValues.lastname! }
                                />
                            </div>
                        </div>
    
                        <div className="row row_form">
                            <div className="col-md">
                                <input
                                    className='input_form'
                                    placeholder={language[lang as T].formEmail}
                                    type='email'
                                    name='email'
                                    required={true}
                                    onChange={ handleInputChange }
                                    value={ formValues.email! }
                                />
                            </div>
                            <div className="col-md">
                                <input
                                    className='input_form'
                                    placeholder={language[lang as T].formRut}
                                    type='text'
                                    name='rut'
                                    required={true}
                                    onChange={ handleInputChange }
                                    onBlur={ handleRut }
                                    value={ formValues.rut! }
                                    maxLength={10}
                                />
                            </div>
                        </div>
    
                        <div className="row row_form">
                            <div className="col-md">
                                <div className="input_form input_date">
                                    <DatePicker
                                        className='select-inside'
                                        selected={ date || undefined }
                                        onChange={ (date: Date) => {
                                            setDate(date);
                                            setFormValues({ ...formValues, birthday: date })
                                            }
                                        }
                                        /* onCalendarClose={ () => {
                                            if (date && visitor.fast > 0) {
                                                const fecha = new Date(date);
                                                const hoy = new Date();
                                                let edad = hoy.getFullYear() - fecha.getFullYear();
                                                const m = hoy.getMonth() - fecha.getMonth();
                                                if (m < 0 || (m === 0 && hoy.getDate() < fecha.getDate())) {
                                                    edad--;
                                                }
                                                if (edad < 18) {
                                                    Swal.fire('Error', 'Debe ser mayor de edad', 'error');
                                                    setDate(null);
                                                }
                                            } 
                                            } 
                                        } */
                                        name='birthday'
                                        placeholderText={language[lang as T].formBirthday}
                                        dateFormat={'d/MM/yyyy'}
                                        locale={es}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                            </div>
    
                            <div className="col-md">
                                <select
                                    className='input_form select_form'
                                    name='residencia'
                                    onChange={ handleSelectChange }
                                    defaultValue={ formValues.residencia! }
                                >
                                    <option value='0'>{language[lang as T].formResident}</option>
                                    <option value='1'>{language[lang as T].formResidentYes}</option>
                                    <option value='2'>{language[lang as T].formExtranjero}</option>
                                </select>
                            </div>
    
                        </div>
    
                        <div className="row row_form">
                            <div className="col-md-6">
                                <ReactFlagsSelect
                                    className='input_form select_form_2'
                                    selected={ formValues.nation! || selected }
                                    searchable
                                    searchPlaceholder={language[lang as T].formSearch}
                                    onSelect={(code: string) => {
                                        setSelected(code);
                                        setFormValues({
                                            ...formValues,
                                            nation: code
                                        });
                                    }}
                                    placeholder={language[lang as T].formNacion}
                                />
                            </div>
                            {
                                (formValues.residencia.toString() === "1") &&
                                <div className="col-md-6">
                                    <select
                                        className='input_form select_form'
                                        name='region'
                                        id='region'
                                        onChange={ handleSelectChange }
                                        value={ formValues.region }
                                        ref={ reg }
                                    >
                                        <option disabled={ true } value='0'>Región</option>
                                        {
                                            (regiones)&&
                                            regiones.map( (region: any) => (
                                                <option key={ region.id } value={ region.id }>{ region.region }</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                        </div>
    
                        <div className="row row_form mt-1">
                            <div className="col-md-7">
                                <div className="form-check check_box">
                                    <input
                                        className='form-check-input check_form'
                                        type='checkbox'
                                        id='promo'
                                        name='promo'
                                        onChange={ handleInputChange }
                                        checked={ formValues.promo || false }
                                    />
                                    <label
                                        className='form-check-label'
                                        htmlFor='promo'
                                    >
                                        {language[lang as T].formPromo}
                                    </label>
                                </div>
    
                                <div className="form-check check_box">
                                    <input
                                        className='form-check-input check_form'
                                        type='checkbox'
                                        name='terms'
                                        onChange={ handleInputChange }
                                        checked={ formValues.terms || false }
                                    />
                                    <label
                                        className='form-check-label'
                                        htmlFor='terms'
                                    >
                                        {/* {language[lang as T].formAcept} <Link to={`/${lang}/terms`} target="_blank" className="link">{language[lang as T].formTerms}</Link> {language[lang as T].formService} */}
                                        {language[lang as T].formAcept} <a href={`${process.env.REACT_APP_WEB_URL}${lang}/${language[lang as T].formLinkTerms}`} target="blank" className="link">{language[lang as T].formTerms}</a> <a href={`${process.env.REACT_APP_WEB_URL}${lang}/${language[lang as T].formLinkPolit}`} target="blank" className="link">{language[lang as T].formPolitic}</a>
                                    </label>
                                </div>
    
                            </div>
    
                            <div className="col-md text-end mt-3">
                                <button
                                    className={`btn-form me-2`}
                                    type='button'
                                    onClick={ handleBack }
                                    //disabled={ !formValues.terms }
                                >
                                    {language[lang as T].formBack}
                                </button>
                                <button
                                    className={`btn-form ${formValues.terms ? 'btn-form-active' : 'btn-form-disabled'}`}
                                    type='submit'
                                    disabled={ !formValues.terms }
                                >
                                    {language[lang as T].selectContinuar}
                                </button>
                            </div>
                        </div>
    
    
                    </form>
                </div>
            </div>
        </>
      )
    
}
