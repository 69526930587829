//CencoX
const baseUrl = process.env.REACT_APP_API_URL;

interface FechtProps {
    endpoint: string;
    data?: any;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
}

export const fetchToken = async ({endpoint, data, method = 'GET'}:FechtProps) => {
    const url = `${ baseUrl }/${ endpoint }`;
    const token = process.env.REACT_APP_API_TOKEN;

    if( method === 'GET' ) {
        return await fetch( url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ token }`
            }
        });
    } else {
        return await fetch( url, {
            method,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ token }`
            },
            body: JSON.stringify( data ),
        });
    }
}