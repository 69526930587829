
type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

const baseUrl = process.env.REACT_APP_API_BBDD;

export const fecthDatabase = async ( data:{[key: string]:any}, endpoint:string, method: Method ) => {
    const url = `${ baseUrl }${ endpoint }`;
    const token = process.env.REACT_APP_API_TOKEN_BBDD;
    //console.log(url);

    if ( method === 'GET' ) {
        return await fetch( url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'auth-token': `${ token }`
            },
        });
    }

    return await fetch( url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `${ token }`
        },
        body: JSON.stringify( data ),
    });
}