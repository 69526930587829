import { useReducer } from "react";
import { fetchToken } from "../helpers/fetch";
//import { getTrxJanus } from '../helpers/intentionPay';
import { buyer, FlowData, ReserveProps, visitor, PayProps } from '../interfaces/interfaces';
import { DataContext } from "./DataContext";
import { dataReducer } from "./DataReducer";
import { fecthDatabase } from '../helpers/fetchDatabase';
import Swal from "sweetalert2";
/* import { complianceData } from '../interfaces/compliance' */

const INITIAL_STATE: FlowData = {
    day: '',
    hour: '',
    visitor: {
        adult: 0,
        child: 0,
        fast: 0
    },
    total: 0,
    paytype: '',
    tickets: '',
    status: '',
    slots: [{}],
    slotSelected: {},
    regiones: [{}],
    buyer: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        residencia: '',
        nation: '',
        rut: '',
    },
    invoice: {
        folio: '',
        urlPdf: '',
        created: false
    },
    trxResponse: {} as any,
    trxDetail: {} as any,
    visitantes: []
};

interface Props {
    children: JSX.Element | JSX.Element[];
}

interface InvoiceResponse {
    outcome: {
        result: {
            invoice?: {
                folio: string;
                urlPdf: string;
            };
            log_create_invoice: boolean;
        };
    };
    statusCode: number;
}

interface Invoice {
    folio: string;
    urlPdf: string;
    create: boolean;
}

export const DataProvider = ({ children }: Props) => {

    const [dataState, dispatch] = useReducer(dataReducer, INITIAL_STATE);

    const handleFetchError = (error: any, defaultMsg: string) => {
        console.error(error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: defaultMsg,
        });
    }

    const getSlots = async () => {
        try {
            const resp = await fetchToken({ endpoint: 'reservation-contexts/sky-costanera/slots' });
            const body = await resp.json();

            dispatch({ type: 'setSlots', payload: body });

        } catch (error) {
            handleFetchError(error, 'Error al obtener los slots');
        }
    }

    const getReserve = (slot: ReserveProps) => {
        dispatch({ type: 'setReserve', payload: slot });
    }

    const getRegiones = async () => {
        try {
            const regiones = await fecthDatabase({}, 'regiones/all', 'GET');
            const body = await regiones.json();

            dispatch({ type: 'setRegiones', payload: body.regiones });

        } catch (error) {
            handleFetchError(error, 'Error al obtener las regiones');
        }
    }

    const cleanReserve = () => {
        dispatch({ type: 'cleanReserve' });
    }

    const getDayHour = (day: string, hour: string) => {
        dispatch({ type: 'setDayAndHour', payload: { day, hour } });
    }

    const setVisitor = (visitor: visitor) => {
        dispatch({ type: 'setVisitor', payload: visitor });
    }

    const setBuyer = (buyer: buyer) => {
        dispatch({ type: 'setBuyer', payload: buyer });
    }

    const cleanBuyer = () => {
        dispatch({ type: 'cleanBuyer' });
    }

    const setPayIntention = async( payIntention: PayProps, date: string, visitor: any, lang: string, visitantes: any ) => {
        //console.log(payIntention, date);
        const dateSelect = date.split('T')[0];
        const hourSelect = `${date.split('T')[1].split(':')[0]}:${date.split('T')[1].split(':')[1]}`;


        let datatrx = {
            id: payIntention.transaction.merchant_transaction_id,
            rut: payIntention.payer.document_number,
            name: payIntention.payer.full_name.split(' ')[0],
            last_name: payIntention.payer.full_name.split(' ')[1],
            email: payIntention.payer.email,
            birthday: payIntention.payer.birthday,
            nation: localStorage.getItem('nation') || 'CL',
            phone: localStorage.getItem('phone') || '0',
            resident: parseInt(localStorage.getItem('residencia')!) || 1,
            region: localStorage.getItem('region') || 0,
            informate: parseInt(localStorage.getItem('promo')!) || 0,
            date: dateSelect,
            hour: hourSelect,
            visitor: visitor,
            terms: parseInt(localStorage.getItem('terms')!) || 1,
            lang: lang,
            visitantes
        }

        try {
            const insertBdTrx = await fecthDatabase(datatrx, 'trxs', 'POST');
            const resptrx = await insertBdTrx.json();

            if (resptrx && resptrx.ok === true) {

                window.location.href = resptrx.janus_url;
                return true;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un error al crear la transacción, intente nuevamente.' + resptrx.msg,
                });
                return false;
            }
        } catch (error) {
            handleFetchError(error, 'Error inesperado al crear la transacción');
        }

    }

    const setPayData = async (id: string) => { //TODO: review try catch
        const searchFolio = await fecthDatabase({}, `trxs/janusid/${ id }`, 'GET');
        const resultFolio = await searchFolio.json();

        if (resultFolio.ok === true) {
            dispatch({ type: 'setTrxResponse', payload: resultFolio.trx });
            dispatch({ type: 'setInvoice', payload: { 
                folio: resultFolio.trx.fiscaldocnumber,
                urlPdf: resultFolio.urlPdf,
                create: true
            } });
            return;
        } else {
            const resp = await fecthDatabase({}, `trxs/janus/${ id }`, 'GET');
            const body = await resp.json();

            dispatch({ type: 'setTrxResponse', payload: body.trx });
            dispatch({ type: 'setInvoice', payload: { 
                folio: body.trx.fiscaldocnumber,
                urlPdf: body.urlPdf,
                create: true
                } });
        }
    }

    const getInvoice = async (id: string) => {
        try {
            if (!id) {
                throw new Error('ID is required');
            }

            const resp = await fetchToken({ endpoint: `payment-intentions/${id}` });
            if (!resp.ok) {
                throw new Error(`Failed to fetch data: ${resp.status}`);
            }

            const body: InvoiceResponse = await resp.json();

            if (body.statusCode !== 404 && body.outcome.result.invoice) {
                const { folio, urlPdf } = body.outcome.result.invoice;
                const invoiceBody: Invoice = {
                    folio,
                    urlPdf,
                    create: body.outcome.result.log_create_invoice,
                };

                dispatch({ type: 'setInvoice', payload: invoiceBody });
            } else {
                console.warn('Invoice not found or status code is 404');
            }
        } catch (error) {
            console.error('Error in getInvoice:', error);
        }
    }

    const setPayment = async (data: any) => {
        try {
            const resp = await fecthDatabase(data, 'payments/add', 'POST');
            await resp.json();
            //console.log(body);
        } catch (error) {
            handleFetchError(error, 'Error al aceptar los términos y condiciones');
        }
    }

    const setTermsAndConditions = async (buyer: buyer) => {
        try {
            const resp = await fecthDatabase(buyer, `compliance/add`, 'POST');
            await resp.json();
        } catch (error) {
            console.log('Términos y condiciones Error', error)
        }
    }

    const getHolidays = async () => {
        try {
            const resp = await fecthDatabase({}, `holidays/getall`, 'GET');
            const body = await resp.json();
            return body
        } catch (error) {
            handleFetchError(error, 'Error al obtener los días festivos');
            return error;
        }
    }

    const getGenerarHorarios = async (day: string, month: string) => {
        try {
            const resp = await fecthDatabase({}, `holidays/gethorario/${day}/${month}/1`, 'GET');
            const body = await resp.json();
            return body;
        } catch (error) {
            handleFetchError(error, 'Error inesperado al generar horarios');
            return {
                ok: false,
                msg: 'Error inesperado al Generar Horarios.'
            }
        }
    }

    const getDocumentpdf = async (document: any) => {
        try {
            const resp = await fecthDatabase(document, 'trxs/document/pdf', 'POST');
            //const body = await resp;
            return resp;
        } catch (error) {
            handleFetchError(error, 'Error inesperado al generar el documento PDF');
            return {
                ok: false,
                msg: 'Error inesperado al Generar Horarios.'
            }
        }
    }

    const getTrxByDate = async (date: string) => {
        try {
            const resp = await fecthDatabase({}, `trxs/maxaforo/${date}`, 'GET');
            const body = await resp.json();
            return body;
        } catch (error) {
            handleFetchError(error, 'Error inesperado al obtener transacciones por fecha');
            return {
                ok: false,
                msg: 'Error inesperado'
            }
        }
    }

    const getTicketActive = async (date: string) => {
        try {
            const tickets = await fecthDatabase({}, `tickets/active/${date}`, 'GET');
            const body = await tickets.json();
            return body.tickets;
        } catch (error) {
            handleFetchError(error, 'Error al obtener los tickets activos');
            return false;
        }
    }

    const setVisitantes = (visitantes: any) => {
        dispatch({ type: 'setVisitantes', payload: visitantes });
    }

    return (
        <DataContext.Provider value={{
            dataState,
            getSlots,
            getReserve,
            getRegiones,
            cleanReserve,
            getDayHour,
            setVisitor,
            setBuyer,
            cleanBuyer,
            setPayIntention,
            setPayData,
            getInvoice,
            setPayment,
            setTermsAndConditions,
            getHolidays,
            getGenerarHorarios,
            getDocumentpdf,
            getTrxByDate,
            getTicketActive,
            setVisitantes
        }}>
            {children}
        </DataContext.Provider>
    )
}
