import React from "react";
import { useParams } from "react-router-dom";
import { language } from "../helpers/lang";

const PaginaNoEncontrada = () => {
  type T = keyof typeof language;
  const idioma = useParams();
  const lang: string = idioma.lang || 'es';

  return (
    <div
      className="text-center"
      style={{ height: "400px", paddingTop: "150px" }}
    >
      <h1>{language[lang as T].lostPageTitle}</h1>
      <p>{language[lang as T].lostPageSubTitle}</p>
      <p>{language[lang as T].lostPageText}</p>
    </div>
  );
};

export default PaginaNoEncontrada;
