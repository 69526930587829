//import styled from "styled-components";
import styled from "styled-components";

interface Props {
    open: boolean;
}

export const NavbarWrapper = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 10vh;
  right: ${({ open }: Props) => (open ? "0" : "-100%")};
  height: 90vh;
  transition: right 0.3s linear;

  .nav-mob { display: none; }

  @media (min-width: 1024px) {
    flex-direction: row;
    position: initial;
    height: auto;
    justify-content: flex-end;
    background-color: white;

    .nav-mob { display: none; }
  }

  @media (max-width: 775px) {
    .nav-mob { display: block; }
  }`;