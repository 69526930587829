import { Route, Routes } from 'react-router-dom';
import { TermsPage } from '../pages/TermsPage';
import { CheckoutPage } from '../pages/CheckoutPage';
import { FormPage } from '../pages/FormPage';
//import { HomePage } from '../pages/HomePage';
import { Paypage } from '../pages/PayPage';
import { ResumeBuy } from '../pages/ResumeBuy';
import { SelectDay } from '../pages/SelectDay';
import { HelpPage } from '../pages/HelpPage';
import PaginaNoEncontrada from '../pages/404';

//type lang = 'es' | 'en' | 'pr';

export const MainRoutes = () => {
  return (
    <>
        <Routes>
            <Route path="/">
                <Route path=":lang/">
                  <Route path="" element={ <SelectDay /> } />
                  <Route path="form" element={ <FormPage /> } />
                  {/* <Route path="selectdate" element={<SelectDay />} /> */}
                  <Route path="resumebuy" element={ <ResumeBuy /> } />
                  <Route path="pay" element={ <Paypage />  } />
                  <Route path="checkout" element={ <CheckoutPage /> } />
                  <Route path="terms" element={ <TermsPage /> } />
                  <Route path="help" element={ <HelpPage /> } />
                  <Route path="undefined" element={ <PaginaNoEncontrada /> } />
                </Route>
            </Route>
            {/* <Route path="*" element={ <Navigate to ="/es/" /> } /> */}
             
            {/* <Route path="*" element={ <HomePage /> } /> */}
        </Routes>
    </>
  )
}
